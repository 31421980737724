import { initialState } from "../initialState";

function reducer(store = initialState, action) {
  switch (action.type) {
    case "READ_USERLANG":
      return {
        ...store,
      };
    case "CHANGE_USERLANG":
      return { ...store, userLang: action.payload };
    default:
      return store;
  }
}

export default reducer;
