import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { changeUserLang } from "../redux/actions/actions";
import iconMucoLarge from "../assets/icon/icon-muco-large.svg";

// tile imports
import tileDefault from "../assets/tiles/tile-default.svg";
import tileDefaultShape from "../assets/tiles/tile-more-soon.svg";
import Header from "../components/Header";
import Footer from "../components/Footer";

class Home extends Component {
  componentDidMount() {}

  render() {
    const userLang = this.props.userLang;

    return (
      <div className="homePage">
        <div className="bgShape1__container"></div>
        <Header />
        <div className="homePage__body">
          <img
            src={iconMucoLarge}
            alt="icon van Muco"
            className="mucoIcon--medium"
          ></img>
          <h1>
            {userLang === "nl"
              ? "Spelenderwijs leren over Muco in de klas"
              : "Apprendre sur muco  en s'amusant en classe"}
          </h1>
          <p
            dangerouslySetInnerHTML={{
              __html:
                userLang === "nl"
                  ? `Met de mucospelen zet je jouw klas aan de slag om te leren over mucoviscidose. Op een actieve manier komen de leerlingen tot kennis over muco, en zo ook tot een gepast begrip voor de ziekte waar een klasgenoot (of een leerkracht) aan lijdt.`
                  : `Avec ce jeu muco, vous mettez votre classe au travail pour en apprendre plus sur la mucoviscidose. De façon active, les élèves apprennent à connaître la muco et arrivent ainsi à une compréhension appropriée de la maladie dont souffre un camarade de classe (ou un instituteur).`,
            }}
          ></p>
          <h2>{userLang === "nl" ? "De spelen" : "Les jeux"}</h2>
          <div className="tiles">
            {userLang === "nl"
              ? // Mapping of Dutch tiles
                this.props.games.map((game) => (
                  <Link
                    to={"/nl" + game.pathname}
                    key={game.id}
                    className="tile"
                  >
                    <img
                      className="tile__img"
                      src={game.nl.tile}
                      alt="Afbeelding van het spel."
                    />
                    <p className="tile__age">{game.nl.age}</p>
                  </Link>
                ))
              : // Mapping of French tiles
                this.props.games.map((game) => (
                  <Link
                    to={"/fr" + game.pathname}
                    key={game.id}
                    className="tile"
                  >
                    <img
                      className="tile__img"
                      src={game.fr.tile}
                      alt="Image du jeu."
                    />
                    <p className="tile__age">{game.fr.age}</p>
                  </Link>
                ))}
            <div className="tile tile--default">
              <h3 className="tile__title">
                {userLang === "nl"
                  ? "Binnenkort meer spelen om te ontdekken!"
                  : "Bientôt plus de jeux à découvrir!"}
              </h3>
              <div className="tile--default-bg">
                <img
                  className="tile__img"
                  src={tileDefault}
                  alt="Standaard tegel zonder link."
                />
                <img
                  className="tile__img"
                  src={tileDefaultShape}
                  alt="Decoratieve vorm bij de tegel."
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userLang: state.reducer.userLang,
  games: state.reducer.games,
});

const mapDispatchToProps = (dispatch) => {
  return {
    changeUserLang: (newLang) => dispatch(changeUserLang(newLang)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
