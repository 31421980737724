import React, { Component } from "react";
import { connect } from "react-redux";
import iconMucoSmall from "../assets/icon/icon-muco-small.svg";
import logoOetang from "../assets/icon/logo_oetang_small.svg";
class Footer extends Component {
  render() {
    const userLang = this.props.userLang;
    return (
      <footer className="footer footer--main">
        <div className="footer__information">
          <img src={iconMucoSmall} alt="Logo van Muco"></img>

          <p>
            {userLang === "nl"
              ? "Driebruggenstraat 124"
              : "Rue des Trois Ponts 124"}{" "}
            <br /> {userLang === "nl" ? "1160 Brussel" : "1160 Bruxelles"}
          </p>

          <h4>Contact</h4>
          <p>
            02 675 57 69
            <br />
            <a href="mailto:info@muco.be">info@muco.be</a>
          </p>
        </div>
        <div className="footer__right">
          <a target="_blank" rel="noreferrer" href="https://oetang.be/">
            <img src={logoOetang} alt="Link Oetang Learning Designers"></img>
          </a>
        </div>
      </footer>
    );
  }
}
const mapStateToProps = (state) => ({
  userLang: state.reducer.userLang,
});

export default connect(mapStateToProps)(Footer);
