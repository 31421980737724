import { initialState } from "../initialState";

export const readUserLang = () => {
  return function (dispatch) {
    dispatch({
      type: "READ_USERLANG",
      payload: initialState.userLang,
    });
  };
};

export const changeUserLang = (newLang) => {
  return function (dispatch) {
    dispatch({
      type: "CHANGE_USERLANG",
      payload: newLang,
    });
  };
};
