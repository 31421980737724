import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import iconMucoLarge from "../assets/icon/icon-muco-large.svg";
import { changeUserLang } from "../redux/actions/actions";

class LanguagePage extends Component {
  componentDidMount() {}
  render() {
    return (
      <div className="landingPage">
        <div className="bgShape1__container"></div>
        <div className="bgShape2__container">
          {/*  
        <img
            src={bgShape2}
            alt="Decoratieve vorm op de achtergrond"
            className="bg-shape2"
          ></img>
    */}
        </div>

        <div className="landingPage__body">
          <img
            src={iconMucoLarge}
            alt="icon van Muco"
            className="mucoIcon--large"
          ></img>
          <div className="languageChoice">
            <Link
              to="/nl/home"
              alt="Button voor Nederlands"
              onClick={() => this.props.changeUserLang("nl")}
              className="languageChoice__button--large"
            >
              <div>
                <span>Ga door </span>
                <span>in het Nederlands &#62;</span>
              </div>
              <div className="languageChoice__button--small">NL</div>
            </Link>
            <Link
              to="/fr/home"
              alt="Button voor Frans"
              onClick={() => this.props.changeUserLang("fr")}
              className="languageChoice__button--large"
            >
              <div>
                <span>Continuer</span> <span>en français &#62;</span>
              </div>
              <div className="languageChoice__button--small">FR</div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    changeUserLang: (newLang) => dispatch(changeUserLang(newLang)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePage);
