import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../../../components/Header";
import iconMucoLarge from "../../../assets/icon/icon-muco-large.svg";
import Footer from "../../../components/Footer";

class Sproetje extends Component {
  render() {
    const game = this.props.game;
    const userLang = this.props.userLang;
    return (
      <div>
        <Header game={this.props.game} back={true} />
        <div className="sproetje">
          <img
            src={iconMucoLarge}
            alt="icon van Muco"
            className="mucoIcon--medium"
          ></img>
          <div className="sproetje__hero-container">
            <img
              src={game.hero}
              alt="Banner van het spel."
              className="sproetje__hero"
            ></img>
            <img
              src={userLang === "nl" ? game.nl.heroTitle : game.fr.heroTitle}
              alt="Title van het spel."
              className="sproetje__title"
            ></img>
          </div>
          <main className="sproetje__body">
            <div className="manual-wrapper">
              <div className="sproetje__body-manual">
                <h3>{userLang === "nl" ? "De handleiding" : "Le manuel"}</h3>
                <ul>
                  <li>
                    <a
                      href={`${
                        userLang === "nl"
                          ? "https://drive.google.com/file/d/1y6kapncaLQqHG-tJC15nBkxsA4dqcwLP/view?usp=sharing"
                          : "https://drive.google.com/file/d/1y9DBpxYPBLI3cnoCS2hHePSSbqX_46Pi/view?usp=sharing"
                      }`}
                      download={
                        userLang === "nl"
                          ? "abc-muco_sproetje_handleiding.pdf"
                          : "abc-muco_lou_manuel.pdf"
                      }
                      target="_blank" rel="noreferrer"
                    >
                      {userLang === "nl"
                        ? "Lees de handleiding"
                        : "Lisez le manuel"}
                    </a>
                  </li>
                </ul>
                <h3 style={{ marginTop: "50px" }}>
                  {userLang === "nl"
                    ? "Teaserfilmpje vooraf"
                    : "Film d'introduction"}
                </h3>
                <ul>
                  <li>
                    <a
                      href={`${
                        userLang === "nl"
                          ? "https://youtu.be/9GS4akXmc00?si=AMRnILJNVwzts-xd"
                          : "https://youtu.be/9GS4akXmc00?si=AMRnILJNVwzts-xd"
                      }`}
                      target="_blank" rel="noreferrer"
                    >
                      {userLang === "nl"
                        ? "Bekijk het teaserfilmpje"
                        : "Regardez le film d'introduction"}
                    </a>
                  </li>
                </ul>
                <h3 style={{ marginTop: "30px" }}>
                  {userLang === "nl"
                    ? "Interactief verhaal"
                    : "Histoire interactive"}
                </h3>
                <ul>
                  <li>
                    <a
                      href={`${
                        userLang === "nl"
                        ? "https://www.abc-muco.org/sproetje/"
                        : "https://www.abc-muco.org/lou/"
                      }`}
                      target="_blank" rel="noreferrer"
                    >
                      {userLang === "nl"
                        ? "Open het interactief luister- en doeverhaal"
                        : "Ouvrez l’onglet ‘histoire interactive'"}
                    </a>
                  </li>
                </ul>
                <h3 style={{ marginTop: "50px" }}>
                  {userLang === "nl"
                    ? "Hoekenwerk"
                    : "Tâches en groupes"}
                </h3>
                <h4 style={{ marginTop: "-10px" }}>{userLang === "nl" ? "Download en print" : "Téléchargez et imprimez"}</h4>{" "}
                <ul>
                  <li>
                    <a
                      href={`${
                        userLang === "nl"
                          ? "https://drive.google.com/file/d/1YCdvxaFRfahH2dTwSS75vGBEjzB4_l8w/view?usp=sharing"
                          : "https://drive.google.com/file/d/1YGOPeM7UwJ2h6oDmI9vPbjExk5KbD_Mi/view?usp=sharing"
                      }`}
                      download={
                        userLang === "nl"
                          ? "abc-muco_sproetje_hoekenwerk_A4_enkelzijdig_1x.pdf"
                          : "abc-muco_lou_tachesengroupe_A4_recto_1x.pdf"
                      }
                      target="_blank" rel="noreferrer"
                    >
                      {userLang === "nl"
                        ? "A4 (enkelzijdig, 1x)"
                        : "A4 (recto, 1x)"}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${
                        userLang === "nl"
                          ? "https://drive.google.com/file/d/1YCvLq24cnKUDWzeltRnKiWfs9DkxJaJz/view?usp=sharing"
                          : "https://drive.google.com/file/d/1YHBFCxtbLqH7FclxmoyCQTSWHQS1T5mm/view?usp=sharing"
                      }`}
                      download={
                        userLang === "nl"
                          ? "abc-muco_sproetje_hoekenwerk_A4_enkelzijdig_2.pdf"
                          : "abc-muco_lou_tachesengroupe_A4_recto_2x.pdf"
                      }
                      target="_blank" rel="noreferrer"
                    >
                      {userLang === "nl"
                        ? "A4 (enkelzijdig, 2x)"
                        : "A4 (recto, 2x)"}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${
                        userLang === "nl"
                          ? "https://drive.google.com/file/d/1YEt7k3pyoDV9obMX78vHN6zbpdBdaqPN/view?usp=sharing"
                          : "https://drive.google.com/file/d/1YGkfMz60byfkMmc3ejhuYCkp9T2gYCKP/view?usp=sharing"
                      }`}
                      download={
                        userLang === "nl"
                          ? "abc-muco_sproetje_hoekenwerk_A3_enkelzijdig_1x.pdf"
                          : "abc-muco_lou_tachesengroupe_A3_recto_1x.pdf"
                      }
                      target="_blank" rel="noreferrer"
                    >
                      {userLang === "nl"
                        ? "A3 (enkelzijdig, 1x))"
                        : "A3 (recto, 1x)"}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="sproetje__body-content">
              <h1>{userLang === "nl" ? game.nl.title : game.fr.title}</h1>
              <div className="ageBtn">
                {userLang === "nl" ? game.nl.age : game.fr.age}
              </div>

              <h2>
                {userLang === "nl" ? "Waarom dit spel?" : "Pourquoi ce jeu?"}
              </h2>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    userLang === "nl"
                      ? `Via dit mucospel maken we  mucoviscidose <b>bespreekbaar</b> bij kinderen van <b>5 tot 7 jaar</b>. De leerling  met muco ervaart hierbij <b>openheid en steun</b>, waardoor hij of zij extra ruimte krijgt om te leren omgaan met de ziekte binnen de klascontext.`
                      : `Grâce à ce jeu, nous rendons la mucoviscidose <b>accessible</b> aux jeunes de <b>5 à 7 ans</b>. L'élève atteint de muco ressent <b>compréhension et soutien<</b>, ce qui lui offre un espace supplémentaire pour apprendre à gérer la maladie dans le contexte de la classe.`,
                }}
              ></p>
              <h2>{userLang === "nl" ? "Korte inhoud" : "Bref contenu"}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    userLang === "nl"
                      ? `De klas wordt meegesleept in een <b>fantasierijk verhaal</b> waarin <b>doe-opdrachten</b> verwerkt zitten. Ga mee op onderzoek met Sproetje de schildpad en ontrafel het mysterie van de kleurrijke bubbels!`
                      : "La classe découvre dans une <b>histoire imaginaire</b>, au fur et à mesure de laquelle se cache une <b>série de missions</b> à accomplir. Partez à l’aventure avec Lou, la petite tortue, et tentez de percer le mystère des bulles magiques !",
                }}
              ></p>
              <p>
                {userLang === "nl"
                  ? "De spelstructuur is als volgt:"
                  : "La structure du jeu est la suivante:"}
              </p>

              <ul>
                <li>
                  <span className="onderdeel-titel">
                    {userLang === "nl" ? "Teaserfilmpje vooraf" : "Film d’introduction"}
                  </span>
                  <span>
                    {userLang === "nl"
                      ? "Wanneer de klas aan de les wil beginnen, verschijnen er plots paarse bubbels op het scherm. De nieuwsgierigheid van de kinderen is gewekt!."
                      : "Lorsque la classe est sur le point de commencer la leçon, des bulles violettes apparaissent soudain à l'écran. La curiosité des enfants est piquée à vif !"}
                  </span>
                </li>
                <li>
                  <span className="onderdeel-titel">
                    {userLang === "nl"
                      ? "Interactief luister- en doeverhaal"
                      : "Histoire interactive"}
                  </span>
                  <span>
                    {userLang === "nl"
                      ? "Aan de hand van een interactief luister- en doeverhaal leert de klas over mucoviscidose en het bubbelmysterie. De leerkracht toont het interactieve verhaal op een (groot) scherm en kiest samen met de kinderen hoe ze het verhaal willen doorlopen."
                      : "Grâce à cette histoire interactive, la classe va apprendre à mieux connaître la mucoviscidose et à comprendre le mystère des bulles magiques. L'enseignant fait défiler l'histoire interactive sur un (grand) écran et choisit avec les enfants la façon dont ils veulent poursuivre l'histoire."}
                  </span>
                </li>
                <li>
                  <span className="onderdeel-titel">
                    {userLang === "nl"
                      ? "Hoekenwerk"
                      : "Tâches en groupes"}
                  </span>
                  <span>
                    {userLang === "nl"
                      ? "Aan de hand van vier fysieke spelletjes wordt de verworven informatie over muco herhaald. De kinderen spelen een memoryspel, een bordspel, doktertje en verzinnen een machine."
                      : "Quatre petits jeux différents vont permettre de récapituler les différentes informations acquises à propos de la muco. Les enfants vont jouer à un jeu de Memory, un jeu de plateau, ils vont jouer au docteur et inventer une machine."}
                  </span>
                </li>
                <li>
                  <span className="onderdeel-titel">
                    {userLang === "nl" ? "Slotshow met quiz" : "Spectacle final avec Quiz"}
                  </span>
                  <span>
                    {userLang === "nl"
                      ? "Aan de hand van een korte quiz reflecteert de klas over wat ze leerden over muco. Hierna volgt een spannende onthulling. In een animatie zien de kinderen de spetterende bubbelshow van Waldo."
                      : "À l'aide d'un petit questionnaire, la classe va pouvoir réfléchir à ce qu'elle a appris sur la mucoviscidose. Le jeu se termine sur une révélation extraordinaire. En guise de clôture, une animation va permettre aux enfants d’assister au magnifique spectacle de bulles magiques de Waldo."}
                  </span>
                </li>
              </ul>
              <h2>{userLang === "nl" ? "Voorbereiding" : "Préparation"}</h2>
              <p
                dangerouslySetInnerHTML={{
                    __html:
                    userLang === "nl"
                        ? "Het spel vraagt als leerkracht een half uur print- en voorbereidingswerk. Daarnaast moet je enkele materialen voorzien. Dankzij de handleiding en alle downloads die je op deze webpagina kan vinden, gaat dit zeer vlot en loont het meer dan de moeite voor een middagvullend programma vol spel- en leerplezier. <br/><br/>Voor je met het interactief luister- en doeverhaal start, controleer of je <a href='https://www.youtube.com/watch?v=5D2PUeqcvxc' target='_blank'>browser-instellingen</a> juist staan. Het is speelbaar op desktop in de browsers Chrome en Edge."
                        : "Ce jeu nécessite une petite demi-heure de préparation. Vous devrez également prévoir un peu de matériel. Sur le site, vous trouverez un manuel qui reprend les explications nécessaires et les différents téléchargements utiles. Tout est fait pour vous faciliter la tâche. Ce jeu permettra à vos élèves de passer une belle après-midi tout en apprenant à mieux connaître la mucoviscidose. <br/><br/>Avant de débuter l'histoire interactive, vérifiez les <a href='https://www.youtube.com/watch?v=DNi4ELq9Quw' target='_blank'>paramètres de votre navigateur</a>. Le jeu est jouable sur ordinateur dans les navigateurs Chrome et Edge."
                }}
                ></p>

            </div>
          </main>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  game: state.reducer.games[0],
  userLang: state.reducer.userLang,
});

export default connect(mapStateToProps)(Sproetje);
