// Image imports
import tileSproetjeNl from "../assets/games/sproetje/tile-sproetje.svg";
import titleSproetjeNl from "../assets/games/sproetje/title-sproetje.svg";
import tileSproetjeFr from "../assets/games/sproetje/tile-lou.svg";
import titleSproetjeFr from "../assets/games/sproetje/title-lou.svg";
import heroSproetje from "../assets/games/sproetje/hero-sproetje.svg";

import tileSolEnAeroNl from "../assets/games/solEnAero/tile-op-avontuur-met-aero-en-sol.svg";
import titleSolEnAeroNl from "../assets/games/solEnAero/title-solEnAero.svg";
import tileSolEnAeroFr from "../assets/games/solEnAero/tile-tous-a-laventure-avec-sol-et-aero.svg";
import titleSolEnAeroFr from "../assets/games/solEnAero/title-solEtAero.svg";
import heroSolEnAero from "../assets/games/solEnAero/hero-solEnAero.svg";

import tileMusicoTourNl from "../assets/games/musicoTour/tile-MusicoTour.svg";
import titleMusicoTourNl from "../assets/games/solEnAero/title-solEnAero.svg";
import tileMusicoTourFr from "../assets/games/musicoTour/tile-MusicoTour.svg";
import titleMusicoTourFr from "../assets/games/solEnAero/title-solEtAero.svg";
import heroMusicoTour from "../assets/games/musicoTour/hero-MusicoTour.svg";
import { createHashHistory } from "history";

export const history = createHashHistory();

export const initialState = {
  userLang: history.location.pathname.slice(1, 3),

  games: [
    {
        id: 0,
        hero: heroSproetje,
        pathname: "/5-7/sproetje-lou",

        nl: {
            title: "Sproetje en de kleurrijke bubbels",
            age: "van 5 tot 7 jaar",
            tile: tileSproetjeNl,
            heroTitle: titleSproetjeNl,
        },
        fr: {
            title: "Lou et les bulles colorées",
            age: "de 5 à 7 ans",
            tile: tileSproetjeFr,
            heroTitle: titleSproetjeFr,
        },
    },
    {
        id: 1,
        hero: heroSolEnAero,
        pathname: "/7-10/sol-aero",

        nl: {
            title: "Op avontuur met Sol en Aero",
            age: "van 7 tot 10 jaar",
            tile: tileSolEnAeroNl,
            heroTitle: titleSolEnAeroNl,
        },
        fr: {
            title: "Tous à l'aventure avec Sol et Aero",
            age: "de 7 à 10 ans",
            tile: tileSolEnAeroFr,
            heroTitle: titleSolEnAeroFr,
        },
    },
    {
        id: 2,
        hero: heroMusicoTour,
        pathname: "/10-13/musico-tour",
  
        nl: {
          title: "Musico Tour",
          age: "van 10 tot 13 jaar",
          tile: tileMusicoTourNl,
          heroTitle: titleMusicoTourNl,
        },
        fr: {
          title: "Musico Tour",
          age: "de 10 à 13 ans",
          tile: tileMusicoTourFr,
          heroTitle: titleMusicoTourFr,
        },
      },
  ],
};
