import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../../../components/Header";
import iconMucoLarge from "../../../assets/icon/icon-muco-large.svg";
import Footer from "../../../components/Footer";

class SolEnAero extends Component {
  render() {
    const game = this.props.game;
    const userLang = this.props.userLang;
    return (
      <div>
        <Header game={this.props.game} back={true} />
        <div className="solEnAero">
          <img
            src={iconMucoLarge}
            alt="icon van Muco"
            className="mucoIcon--medium"
          ></img>
          <div className="solEnAero__hero-container">
            <img
              src={game.hero}
              alt="Banner van het spel."
              className="solEnAero__hero"
            ></img>
            <img
              src={userLang === "nl" ? game.nl.heroTitle : game.fr.heroTitle}
              alt="Title van het spel."
              className="solEnAero__title"
            ></img>
          </div>
          <main className="solEnAero__body">
            <div className="manual-wrapper">
              <div className="solEnAero__body-manual">
                <h3>{userLang === "nl" ? "De handleiding" : "Le manuel"}</h3>
                <ul>
                  <li>
                    <a
                      href={`./downloads/sol_aero/${
                        userLang === "nl"
                          ? "nl/handleiding.pdf"
                          : "fr/manuel.pdf"
                      }`}
                      download={
                        userLang === "nl"
                          ? "abc-muco_sol&aero_handleiding.pdf"
                          : "abc-muco_sol&aero_manuel.pdf"
                      }
                    >
                      {userLang === "nl"
                        ? "Lees de handleiding"
                        : "Lisez le manuel"}
                    </a>
                  </li>
                </ul>
                <h3 style={{ marginTop: "50px" }}>
                  {userLang === "nl"
                    ? "Download en print"
                    : "Documents à télécharger et imprimer"}
                </h3>
                <h4>{userLang === "nl" ? "Intro" : "Introduction"}</h4>
                <ul>
                  <li>
                    <a
                      href={`./downloads/sol_aero/${
                        userLang === "nl"
                          ? "nl/intro_A4_enkelzijdig_1x.pdf"
                          : "fr/introduction_A4_recto_1x.pdf"
                      }`}
                      download={
                        userLang === "nl"
                          ? "abc-muco_sol&aero_intro_A4_enkelzijdig.pdf"
                          : "abc-muco_sol&aero_introduction_A4_recto.pdf"
                      }
                    >
                      {userLang === "nl"
                        ? "A4 (enkelzijdig, 1x)"
                        : "A4 (recto, 1x)"}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`./downloads/sol_aero/${
                        userLang === "nl"
                          ? "nl/intro_A3_enkelzijdig_1x.pdf"
                          : "fr/introduction_A3_recto_1x.pdf"
                      }`}
                      download={
                        userLang === "nl"
                          ? "abc-muco_sol&aero_intro_A3_enkelzijdig_1x.pdf"
                          : "abc-muco_sol&aero_introduction_A3_recto_1x.pdf"
                      }
                    >
                      {userLang === "nl"
                        ? "A3 (enkelzijdig, 1x)"
                        : "A3 (recto, 1x)"}
                    </a>
                  </li>
                </ul>
                <h4>{userLang === "nl" ? "Deel 1" : "1ère partie"}</h4>{" "}
                <ul>
                  <li>
                    <a
                      href={`./downloads/sol_aero/${
                        userLang === "nl"
                          ? "nl/deel-1_A4_enkelzijdig_1x.pdf"
                          : "fr/1ere-partie_A4_recto_1x.pdf"
                      }`}
                      download={
                        userLang === "nl"
                          ? "abc-muco_sol&aero_deel-1_A4_enkelzijdig_1x.pdf"
                          : "abc-muco_sol&aero_1ere-partie_A4_recto_1x.pdf"
                      }
                    >
                      {userLang === "nl"
                        ? "A4 (enkelzijdig, 1x)"
                        : "A4 (recto, 1x)"}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`./downloads/sol_aero/${
                        userLang === "nl"
                          ? "nl/deel_1_A4_dubbelzijdig_1x.pdf"
                          : "fr/1ere-partie_A4_recto-verso_1x.pdf"
                      }`}
                      download={
                        userLang === "nl"
                          ? "abc-muco_sol&aero_deel-1_A4_dubbelzijdig_1x.pdf"
                          : "abc-muco_sol&aero_1ère-partie_A4_recto-verso_1x.pdf"
                      }
                    >
                      {userLang === "nl"
                        ? "A4 (dubbelzijdig, 1x)"
                        : "A4 (recto-verso, 1x)"}
                    </a>
                  </li>
                </ul>
                <h4>{userLang === "nl" ? "Deel 2" : "2ème partie"}</h4>{" "}
                <ul>
                  <li>
                    <a
                      href={`./downloads/sol_aero/${
                        userLang === "nl"
                          ? "nl/deel-2_A4_enkelzijdig_2x.pdf"
                          : "fr/2eme-partie_A4_recto_2x.pdf"
                      }`}
                      download={
                        userLang === "nl"
                          ? "abc-muco_sol&aero_deel-2_A4_enkelzijdig_2x.pdf"
                          : "abc-muco_sol&aero_2eme-partie_A4_recto_2x.pdf"
                      }
                    >
                      {userLang === "nl"
                        ? "A4 (enkelzijdig, 2x)"
                        : "A4 (recto, 2x)"}
                    </a>
                  </li>
                </ul>
                <h4>{userLang === "nl" ? "Slot" : "Rétrospection"}</h4>{" "}
                <ul>
                  <li>
                    <a
                      href={`./downloads/sol_aero/${
                        userLang === "nl"
                          ? "nl/slot_A4_dubbelzijdig_1x.pdf"
                          : "fr/retrospection_A4_recto-verso_1x.pdf"
                      }`}
                      download={
                        userLang === "nl"
                          ? "abc-muco_sol&aero_slot_A4_dubbelzijdig_1x.pdf"
                          : "abc-muco_sol&aero_retrospection_A4_recto-verso_1x.pdf"
                      }
                    >
                      {userLang === "nl"
                        ? "A4 (dubbelzijdig, 1x)"
                        : "A4 (recto-verso, 1x)"}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="solEnAero__body-content">
              <h1>{userLang === "nl" ? game.nl.title : game.fr.title}</h1>
              <div className="ageBtn">
                {userLang === "nl" ? game.nl.age : game.fr.age}
              </div>

              <h2>
                {userLang === "nl" ? "Waarom dit spel?" : "Pourquoi ce jeu?"}
              </h2>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    userLang === "nl"
                      ? `Via dit mucospel maken we  mucoviscidose <b>bespreekbaar</b> bij kinderen van <b>7 tot 10 jaar</b>. De leerling  met muco ervaart hierbij <b>openheid en steun</b>, waardoor hij of zij extra ruimte krijgt om te leren omgaan met de ziekte binnen de klascontext.`
                      : `À l'aide de ce jeu muco, nous faisons en sorte que des enfants de 7 à 10 ans puissent <b>parler ouvertement</b> de la mucoviscidose. L'élève atteint de muco ressent ainsi une <b>ouverture d'esprit et du soutien</b> par rapport à sa maladie, ce qui lui donne plus d'espace pour apprendre à la gérer dans la situation de classe.`,
                }}
              ></p>
              <h2>{userLang === "nl" ? "Korte inhoud" : "Résumé"}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    userLang === "nl"
                      ? `Een <b>fantasieverhaal</b> en <b>onderzoekende opdrachten</b>. Dat zijn de twee pijlers van dit spel. Hiermee sluiten we aan op de leeftijd van de leerlingen: ruimte voor verbeelding en actie dus! De leerlingen gaan op avontuur in het magische land Solicië, waar ze ridder Sol helpen met een probleem.`
                      : "Une <b>histoire fantastique</b> et des <b>tâches d'investigation</b>. Voilà les deux piliers de ce jeu qui nous permettent d'adhérer à l'âge des élèves. Place à l'imagination et à l'action! Les élèves partent à l'aventure dans le paisible pays magique de Solicie où ils aident le Chevalier Sol à résoudre un problème.",
                }}
              ></p>
              <p>
                {userLang === "nl"
                  ? "De spelstructuur is als volgt:"
                  : "La structure du jeu est la suivante:"}
              </p>

              <ul>
                <li>
                  <span className="onderdeel-titel">
                    {userLang === "nl" ? "Intro" : "Intro"}
                  </span>
                  <span>
                    {userLang === "nl"
                      ? "De leerlingen worden getriggerd door een geheimzinnige brief en andere hints."
                      : "Les élèves sont titillés par une lettre mystérieuse et quelques autres indices."}
                  </span>
                </li>
                <li>
                  <span className="onderdeel-titel">
                    {userLang === "nl"
                      ? "Deel 1: Opdrachten ter verkenning"
                      : "1ère partie: Tâches de reconnaissance"}
                  </span>
                  <span>
                    {userLang === "nl"
                      ? "De leerlingen voeren in groepjes één van de acht verkenningsopdrachten uit. Zo ontdekken ze  elk een stuk van het verhaal en al wat informatie over muco."
                      : "Les élèves exécutent en petits groupes chacune des huit tâches de reconnaissance. Chaque groupe découvre ainsi une partie de l'histoire et un début d'information sur la muco."}
                  </span>
                </li>
                <li>
                  <span className="onderdeel-titel">
                    {userLang === "nl"
                      ? "Deel 2: Opdrachten ter verdieping"
                      : "2ème partie: Tâches d'approfondissement"}
                  </span>
                  <span>
                    {userLang === "nl"
                      ? "De leerlingen komen aan de hand van een spannend experiment of klein onderzoek meer te  weten over de ziekte mucoviscidose."
                      : "Les élèves en apprennent plus sur la muco à l'aide d'expérimentations passionnantes ou de petites recherches."}
                  </span>
                </li>
                <li>
                  <span className="onderdeel-titel">
                    {userLang === "nl" ? "Terugblik" : "Introduction"}
                  </span>
                  <span>
                    {userLang === "nl"
                      ? "Tijdens de terugblik reflecteert de klas samen over wat ze leerden."
                      : "Lors de la rétrospection, la classe réfléchit ensemble à ce qu'elle a appris."}
                  </span>
                </li>
              </ul>
              <h2>{userLang === "nl" ? "Voorbereiding" : "Préparation"}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    userLang === "nl"
                      ? `Het spel vraagt als leerkracht een uur print- en voorbereidingswerk. Daarnaast moet je  enkele materialen voorzien. Dankzij de handleiding en alle downloads die je op deze webpagina kan vinden, gaat dit zeer vlot en loont het meer dan de moeite voor 3 volle lestijden spel- en leerplezier.`
                      : "Pour l'instituteur, le jeu demande une heure de travail d'impression et de préparation. Vous devrez également prévoir quelques matériaux. Grâce au manuel et à tous les documents à télécharger qui vous trouverez au site web, vous aurez du plaisir à préparer trois heures complètes de cours pour apprendre tout en jouant.",
                }}
              ></p>
            </div>
          </main>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  game: state.reducer.games[1],
  userLang: state.reducer.userLang,
});

export default connect(mapStateToProps)(SolEnAero);
