import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import iconMucoHeader from "../assets/icon/icon-muco-header.svg";
import { changeUserLang } from "../redux/actions/actions";
import { history } from "../redux/initialState";
class Header extends Component {
  render() {
    const userLang = this.props.userLang;

    return (
      <header>
        <div className="header__leftside">
          <Link
            to={userLang === "nl" ? "/nl/home" : "/fr/home"}
            style={
              this.props.back === true
                ? { visibility: "visible" }
                : { visibility: "hidden" }
            }
          >
            &lt; {userLang === "nl" ? "terug" : "retour"}
            <span>
              {userLang === "nl"
                ? " naar het speloverzicht"
                : " à l'aperçu des jeux"}
            </span>
          </Link>
        </div>
        <div className="header__rightside">
          <a
            target="_blank"
            rel="noreferrer"
            href={"https://www.muco.be/" + userLang}
          >
            <img
              className="iconMucoHeader"
              alt="Logo van Muco"
              src={iconMucoHeader}
            ></img>
          </a>

          <Link
            onClick={() => this.props.changeUserLang("nl")}
            to={history.location.pathname
              .substring(
                history.location.pathname.indexOf("/fr/"),
                history.location.pathname.length
              )
              .replace("/fr/", "/nl/")}
            className={`header__langChoice ${
              this.props.userLang === "nl" ? "header__langChoice--active" : ""
            }`}
          >
            NL
          </Link>
          <Link
            onClick={() => this.props.changeUserLang("fr")}
            to={history.location.pathname
              .substring(
                history.location.pathname.indexOf("/nl/"),
                history.location.pathname.length
              )
              .replace("/nl/", "/fr/")}
            className={`header__langChoice ${
              this.props.userLang === "fr" ? "header__langChoice--active" : ""
            }`}
          >
            FR
          </Link>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  userLang: state.reducer.userLang,
  games: state.reducer.games,
});

const mapDispatchToProps = (dispatch) => {
  return {
    changeUserLang: (newLang) => dispatch(changeUserLang(newLang)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
