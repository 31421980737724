import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Sproetje from "./pages/games/5-7/Sproetje";
import SolEnAero from "./pages/games/7-10/SolEnAero";
import MusicoTour from "./pages/games/10-13/MusicoTour";
import Home from "./pages/Home";
import LanguagePage from "./pages/LanguagePage";
import { changeUserLang } from "./redux/actions/actions";
import { history } from "./redux/initialState";
import { withRouter } from "react-router";
import ScrollToTop from "./components/ScrollToTop";

class App extends Component {
  componentDidMount() {
    // let language = window.location.pathname.slice(1, 3);
    // if (language === "nl" || "fr") {
    //   this.props.changeUserLang(language);
    // }
    console.log(this.props.history);
    // console.log(language);
    // console.log(this.props);

    let language = this.props.history.location.pathname.slice(1, 3);

    if (language !== "" || null) {
      this.props.changeUserLang(language);
    }
  }

  render() {
    return (
      <div>
        <ScrollToTop>
          <Switch>
            <Route path={["/nl/5-7/sproetje-lou", "/fr/5-7/sproetje-lou"]}>
              <Sproetje />
            </Route>
            <Route path={["/nl/7-10/sol-aero", "/fr/7-10/sol-aero"]}>
              <SolEnAero />
            </Route>
            <Route path={["/nl/10-13/musico-tour", "/fr/10-13/musico-tour"]}>
              <MusicoTour />
            </Route>
            {this.props.userLang !== null ? (
              <Route path={["/nl/home", "/fr/home"]}>
                <Home />
              </Route>
            ) : (
              ""
            )}
            <Route exact path={["/nl/home", "/nl", "/fr", "/fr/home"]}>
              <Home />
            </Route>
            <Route path="/">
              <LanguagePage />
            </Route>
          </Switch>
        </ScrollToTop>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userLang: state.reducer.userLang,
});

const mapDispatchToProps = (dispatch) => {
  return {
    changeUserLang: (newLang) => dispatch(changeUserLang(newLang)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
