import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../../../components/Header";
import iconMucoLarge from "../../../assets/icon/icon-muco-large.svg";
import Footer from "../../../components/Footer";

class MusicoTour extends Component {
  render() {
    const game = this.props.game;
    const userLang = this.props.userLang;
    return (
      <div>
        <Header game={this.props.game} back={true} />
        <div className="musicoTour">
          <img
            src={iconMucoLarge}
            alt="icon van Muco"
            className="mucoIcon--medium"
          ></img>
          <div className="musicoTour__hero-container">
            <img
              src={game.hero}
              alt="Banner van het spel."
              className="musicoTour__hero"
            ></img>
          </div>
          <main className="musicoTour__body">
            <div className="manual-wrapper">
              <div className="musicoTour__body-manual">
                <h3>{userLang === "nl" ? "De handleiding" : "Le manuel"}</h3>
                <ul>
                  <li>
                    <a
                      href={`./downloads/musico_tour/${
                        userLang === "nl"
                          ? "nl/handleiding.pdf"
                          : "fr/manuel.pdf"
                      }`}
                      download={
                        userLang === "nl"
                          ? "abc-muco_musico-tour_handleiding.pdf"
                          : "abc-muco_musico-tour_manuel.pdf"
                      }
                    >
                      {userLang === "nl"
                        ? "Lees de handleiding"
                        : "Lisez le manuel"}
                    </a>
                  </li>
                </ul>
                <h3 style={{ marginTop: "50px" }}>
                  {userLang === "nl"
                    ? "Download en print"
                    : "Documents à télécharger et imprimer"}
                </h3>
                <h4>{userLang === "nl" ? "Intro" : "Introduction"}</h4>
                <ul>
                  <li>
                    <a
                      href={`./downloads/musico_tour/${
                        userLang === "nl"
                          ? "nl/intro_A4_enkelzijdig_1_per_team.pdf"
                          : "fr/intro_A4_recto_1_par_equipe.pdf"
                      }`}
                      download={
                        userLang === "nl"
                          ? "abc-muco_musico-tour_intro_A4_enkelzijdig_1_per_team.pdf"
                          : "abc-muco_musico-tour_intro_A4_recto_1_par_equipe.pdf"
                      }
                    >
                      {userLang === "nl"
                        ? "A4 (enkelzijdig, 1 per team)"
                        : "A4 (recto, 1 par équipe)"}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`./downloads/musico_tour/${
                        userLang === "nl"
                          ? "nl/intro_A4_enkelzijdig_1x.pdf"
                          : "fr/intro_A4_recto_1x.pdf"
                      }`}
                      download={
                        userLang === "nl"
                          ? "abc-muco_musico-tour_intro_A4_enkelzijdig.pdf"
                          : "abc-muco_musico-tour_intro_A4_recto_1x.pdf"
                      }
                    >
                      {userLang === "nl"
                        ? "A4 (enkelzijdig, 1x)"
                        : "A4 (recto, 1x)"}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`./downloads/musico_tour/${
                        userLang === "nl"
                          ? "nl/intro_A4_enkelzijdig_3x.pdf"
                          : "fr/intro_A4_recto_3x.pdf"
                      }`}
                      download={
                        userLang === "nl"
                          ? "abc-muco_musico-tour_intro_A4_enkelzijdig_3x.pdf"
                          : "abc-muco_musico-tour_intro_A4_recto_3x.pdf"
                      }
                    >
                      {userLang === "nl"
                        ? "A4 (enkelzijdig, 3x)"
                        : "A4 (recto, 3x)"}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`./downloads/musico_tour/${
                        userLang === "nl"
                          ? "nl/intro_A3_enkelzijdig_1_per_team.pdf"
                          : "fr/intro_A3_recto_1_par_equipe.pdf"
                      }`}
                      download={
                        userLang === "nl"
                          ? "abc-muco_musico-tour_intro_A3_enkelzijdig_1_per_team.pdf"
                          : "abc-muco_musico-tour_intro_A3_recto_1_par_equipe.pdf"
                      }
                    >
                      {userLang === "nl"
                        ? "A3 (enkelzijdig, 1 per team)"
                        : "A3 (recto, 1 par équipe)"}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`./downloads/musico_tour/${
                        userLang === "nl"
                          ? "nl/intro_A3_enkelzijdig_1x.pdf"
                          : "fr/intro_A3_recto_1x.pdf"
                      }`}
                      download={
                        userLang === "nl"
                          ? "abc-muco_musico-tour_intro_A3_enkelzijdig_1x.pdf"
                          : "abc-muco_musico-tour_intro_A3_recto_1x.pdf"
                      }
                    >
                      {userLang === "nl"
                        ? "A3 (enkelzijdig, 1x)"
                        : "A3 (recto, 1x)"}
                    </a>
                  </li>
                  <li>
                            <a
                        href={`./downloads/musico_tour/${
                            userLang === "nl"
                            ? "nl/intro_speluitleg_projecteren.pdf"
                            : "fr/intro_explication_jeu_projeter.pdf"
                        }`}
                        download={
                            userLang === "nl"
                            ? "abc-muco_musico-tour_intro_speluitleg_projecteren.pdf"
                            : "abc-muco_musico-tour_jeu_A4_en_partie_numerique_recto_2x.pdf"
                        }
                        >
                        {userLang === "nl"
                            ? "Speluitleg (projecteren)"
                            : "Explication du jeu (projeter)"}
                        </a>
                        </li>
                </ul>
                <h4>{userLang === "nl" ? "Spel" : "Jeu"}</h4>{" "}
                <ul>
                  <li>
                    <h5>{userLang === "nl" ? "Volledig print" : "Print total"}</h5>
                    <ul>
                        <li>
                            <a
                        href={`./downloads/musico_tour/${
                            userLang === "nl"
                            ? "nl/spel_A4_volledig_print_enkelzijdig_1_per_team.pdf"
                            : "fr/jeu_A4_print_total_recto_1_par_equipe.pdf"
                        }`}
                        download={
                            userLang === "nl"
                            ? "abc-muco_musico-tour_spel_A4_volledig_print_enkelzijdig_1_per_team.pdf"
                            : "abc-muco_musico-tour_jeu_A4_print_total_recto_1_par_equipe.pdf"
                        }
                        >
                        {userLang === "nl"
                            ? "A4 (enkelzijdig, 1 per team)"
                            : "A4 (recto, 1 par équipe)"}
                        </a>
                        </li>
                        <li>
                            <a
                        href={`./downloads/musico_tour/${
                            userLang === "nl"
                            ? "nl/spel_A4_volledig_print_enkelzijdig_1x.pdf"
                            : "fr/jeu_A4_print_total_recto_1x.pdf"
                        }`}
                        download={
                            userLang === "nl"
                            ? "abc-muco_musico-tour_spel_A4_volledig_print_enkelzijdig_1x.pdf"
                            : "abc-muco_musico-tour_jeu_A4_print_total_recto_1x.pdf"
                        }
                        >
                        {userLang === "nl"
                            ? "A4 (enkelzijdig, 1x)"
                            : "A4 (recto, 1x)"}
                        </a>
                        </li>
                        <li>
                            <a
                        href={`./downloads/musico_tour/${
                            userLang === "nl"
                            ? "nl/spel_A4_volledig_print_enkelzijdig_2x.pdf"
                            : "fr/jeu_A4_print_total_recto_2x.pdf"
                        }`}
                        download={
                            userLang === "nl"
                            ? "abc-muco_musico-tour_spel_A4_volledig_print_enkelzijdig_2x.pdf"
                            : "abc-muco_musico-tour_jeu_A4_print_total_recto_2x.pdf"
                        }
                        >
                        {userLang === "nl"
                            ? "A4 (enkelzijdig, 2x)"
                            : "A4 (recto, 2x)"}
                        </a>
                        </li>
                    </ul>
                  </li>
                  <li>
                    <h5>{userLang === "nl" ? "Deels digitaal" : "En partie numérique"}</h5>
                    <ul>
                        <li>
                            <a
                        href={`./downloads/musico_tour/${
                            userLang === "nl"
                            ? "nl/spel_A4_deels_digitaal_enkelzijdig_1_per_team.pdf"
                            : "fr/jeu_A4_en_partie_numerique_recto_1_par_equipe.pdf"
                        }`}
                        download={
                            userLang === "nl"
                            ? "abc-muco_musico-tour_spel_A4_deels_digitaal_enkelzijdig_1_per_team.pdf"
                            : "abc-muco_musico-tour_jeu_A4_en_partie_numerique_recto_1_par_equipe.pdf"
                        }
                        >
                        {userLang === "nl"
                            ? "A4 (enkelzijdig, 1 per team)"
                            : "A4 (recto, 1 par équipe)"}
                        </a>
                        </li>
                        <li>
                            <a
                        href={`./downloads/musico_tour/${
                            userLang === "nl"
                            ? "nl/spel_A4_deels_digitaal_enkelzijdig_1x.pdf"
                            : "fr/jeu_A4_en_partie_numerique_recto_1x.pdf"
                        }`}
                        download={
                            userLang === "nl"
                            ? "abc-muco_musico-tour_spel_A4_deels_digitaal_enkelzijdig_1x.pdf"
                            : "abc-muco_musico-tour_jeu_A4_en_partie_numerique_recto_1x.pdf"
                        }
                        >
                        {userLang === "nl"
                            ? "A4 (enkelzijdig, 1x)"
                            : "A4 (recto, 1x)"}
                        </a>
                        </li>
                        <li>
                            <a
                        href={`./downloads/musico_tour/${
                            userLang === "nl"
                            ? "nl/spel_A4_deels_digitaal_enkelzijdig_2x.pdf"
                            : "fr/jeu_A4_en_partie_numerique_recto_2x.pdf"
                        }`}
                        download={
                            userLang === "nl"
                            ? "abc-muco_musico-tour_spel_A4_deels_digitaal_enkelzijdig_2x.pdf"
                            : "abc-muco_musico-tour_jeu_A4_en_partie_numerique_recto_2x.pdf"
                        }
                        >
                        {userLang === "nl"
                            ? "A4 (enkelzijdig, 2x)"
                            : "A4 (recto, 2x)"}
                        </a>
                        </li>
                    </ul>
                  </li>
                </ul>
                <h4>{userLang === "nl" ? "Finale" : "Final"}</h4>{" "}
                <ul>
                  <li>
                    <a
                      href={`./downloads/musico_tour/${
                        userLang === "nl"
                          ? "nl/finale_A4_enkelzijdig_1x.pdf"
                          : "fr/finale_A4_recto_1x.pdf"
                      }`}
                      download={
                        userLang === "nl"
                          ? "abc-muco_musico-tour_finale_A4_enkelzijdig_1x.pdf"
                          : "abc-muco_musico-tour_finale_A4_recto_1x.pdf"
                      }
                    >
                      {userLang === "nl"
                        ? "A4 (enkelzijdig, 1x)"
                        : "A4 (recto, 1x)"}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`./downloads/musico_tour/${
                        userLang === "nl"
                          ? "nl/finale_quiz_projecteren.pdf"
                          : "fr/finale_quiz_projeter.pdf"
                      }`}
                      download={
                        userLang === "nl"
                          ? "abc-muco_musico-tour_finale_quiz_projecteren.pdf"
                          : "abc-muco_musico-tour_finale_quiz_projeter.pdf"
                      }
                    >
                      {userLang === "nl"
                        ? "Quiz (projecteren)"
                        : "Quiz (projeter)"}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="musicoTour__body-content">
              <h1>{userLang === "nl" ? game.nl.title : game.fr.title}</h1>
              <div className="ageBtn">
                {userLang === "nl" ? game.nl.age : game.fr.age}
              </div>

              <h2>
                {userLang === "nl" ? "Waarom dit spel?" : "Pourquoi ce jeu?"}
              </h2>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    userLang === "nl"
                      ? `Via dit mucospel maken we mucoviscidose <b>bespreekbaar</b> bij jongeren van <b>10 tot 13 jaar</b>. De leerling met muco ervaart hierbij <b>openheid en steun</b>, waardoor die extra ruimte krijgt om te leren omgaan met de ziekte binnen de klascontext.`
                      : `À l'aide de ce jeu muco, nous faisons en sorte que des enfants de <b>10 à 13 ans</b> puissent <b>parler ouvertement</b> de la mucoviscidose. L'élève atteint de mucoviscidose ressent ainsi une <b>ouverture d'esprit et du soutien</b> par rapport à sa maladie, ce qui lui donne plus d'espace pour apprendre à la gérer dans la situation de classe.`,
                }}
              ></p>
              <h2>{userLang === "nl" ? "Korte inhoud" : "Résumé"}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    userLang === "nl"
                      ? `Een <b>realistisch verhaal</b> en <b>onderzoekende en empathische opdrachten</b>. Dat zijn de pijlers van dit spel. Hiermee sluiten we aan op de leeftijd van de leerlingen: realistisch met enkele elementen die tot de verbeelding spreken en actie! De leerlingen gaan met hun band op tour. Tijdens deze wereldreis komt de band verschillende obstakels tegen. De leerlingen helpen hun band deze hindernissen te overwinnen.`
                      : "Une <b>histoire réaliste</b> et des <b>missions investigatrices et empathiques</b>, voici les piliers de ce jeu qui nous permettent de nous brancher sur l'âge des élèves. Un jeu réaliste qui laisse aussi de la place à l'imagination et à l'action ! Les élèves partent en tournée avec leur groupe de musique. Lors de cette tournée mondiale, le groupe rencontre plusieurs obstacles. Les élèves aident leur groupe à surmonter ces obstacles.",
                }}
              ></p>
              <p>
                {userLang === "nl"
                  ? "De spelstructuur is als volgt:"
                  : "Le jeu est structuré comme suit :"}
              </p>

              <ul>
                <li>
                  <span className="onderdeel-titel">
                    {userLang === "nl" ? "Intro" : "Introduction"}
                  </span>
                  <span>
                    {userLang === "nl"
                      ? "De leerlingen worden getriggerd door een flyer, spelbord en introfilmpje. Ze ontwerpen hun band en pion en leren hoe het bordspel werkt. Ondertussen komen ze te weten dat 1 van de bandleden muco heeft, maar wat is dit?"
                      : "Les élèves sont animés par une affiche, un plateau de jeu et un film d'introduction. Ils créent leur groupe de musique et leur pion et apprennent comment fonctionne le plateau de jeu. Entretemps ils découvrent qu'un des membres de leur groupe est atteint de mucoviscidose, mais c'est quoi, en fait ?"}
                  </span>
                </li>
                <li>
                  <span className="onderdeel-titel">
                    {userLang === "nl"
                      ? "Spelbord"
                      : "Le plateau de jeu"}
                  </span>
                  <span>
                    {userLang === "nl"
                      ? "De leerlingen ontdekken aan de hand van 4 grote opdrachten wat muco is en welke invloed dit heeft op het dagelijkse leven en de toekomst. Tussen de grote opdrachten door krijgen de leerlingen extra weetjes over muco."
                      : "À travers quatre grandes missions, les élèves découvrent ce que c'est que la mucoviscidose et en quoi cette maladie a une influence sur le quotidien et sur l'avenir. Entre ces grandes missions, les élèves reçoivent des informations supplémentaires sur la mucoviscidose."}
                  </span>
                </li>
                <li>
                  <span className="onderdeel-titel">
                    {userLang === "nl"
                      ? "Finale"
                      : "La finale"}
                  </span>
                  <span>
                    {userLang === "nl"
                      ? "Tijdens de finale krijgen de leerlingen 5 stellingen waarmee je als leerkracht hun kennis over muco kan toetsen en eventuele gaten of misinterpretaties opspoort."
                      : "Lors de la finale, les élèves reçoivent cinq affirmations qui vous permettent, en tant qu'instituteur.trice, de tester leurs connaissances sur la mucoviscidose et de détecter d'éventuelles lacunes ou des interprétations erronées."}
                  </span>
                </li>
              </ul>
              <h2>{userLang === "nl" ? "Voorbereiding" : "Préparation"}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    userLang === "nl"
                      ? `Het spel vraagt als leerkracht een half uur print- en voorbereidingswerk. Daarnaast moet je enkele materialen voorzien. Dankzij de handleiding en alle downloads die je op deze webpagina kan vinden, gaat dit zeer vlot en loont het meer dan de moeite voor een middagvullend programma vol spel- en leerplezier.`
                      : "Pour l'instituteur.trice, le jeu demande une demi-heure de travail d'impression et de préparation. Vous devrez également prévoir quelques matériaux. Grâce à ce manuel et au site web, où vous trouverez tous les documents à télécharger, la préparation sera très facile et en vaut largement la peine pour une après-midi pleine de jeux et de choses à apprendre.",
                }}
              ></p>
            </div>
          </main>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  game: state.reducer.games[2],
  userLang: state.reducer.userLang,
});

export default connect(mapStateToProps)(MusicoTour);
